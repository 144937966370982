.resources {
  display: flex;
  align-items: center;
  justify-content: center;
}
.resources-container {
  max-width: 1200px;
  margin: 40px 40px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.resources-title {
  width: 100%;
  margin-left: 20px;
}
.resources-title h2 {
  text-align: left;
  font-size: 1.75rem;
  font-weight: 400;
  font-style: normal;
  /* text-transform: uppercase; */
  margin-block-start: 10px;
  margin-block-end: 0.2em;
  margin-bottom: 0;
}
.resources-content-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

/* Tablet */
@media only screen and (min-width: 768px) {
  .resources-title {
    margin-left: 0;
  }
}

/* Desktop */
@media only screen and (min-width: 1200px) {
  .resources-title h2 {
    font-size: 2.5rem;
  }
}
