.leadership {
  display: flex;
  align-items: center;
  justify-content: center;
}
.leadership-container {
  max-width: 1200px;
  margin: 40px 0;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
}
.leadership-container .leadership-section-title {
  width: 100%;
  margin-left: 20px;
}
.leadership-container h2 {
  font-size: 1.75rem;
  font-weight: 400;
  font-style: normal;
  /* text-transform: uppercase; */
  margin: 0;
  margin-block-start: 0.83em;
}
.leadership-officers {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
}
.leadership-faculty {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-evenly;
}
.leadership-alumni-blurb {
  margin-left: 20px;
  width: 100%;
}
.leadership-alumni-blurb p {
  text-align: left;
}
.leadership-alumni {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-left: 20px;
}
.leadership-alumlist h3 {
  font-weight: 500;
}
@media only screen and (min-width: 1200px) {
  .leadership-container h2 {
    font-size: 2.5rem;
  }
}
