a {
  text-decoration: none;
}
#navbar {
  position: fixed;
  z-index: 10; /* Stay on top of everything */
  left: 0;
  top: 0;
  width: 100vw;
  box-shadow: 0px 0px 10px black;
}
#navbar .nav-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1300px;
}
#navbar .navbar-container {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
}
#navbar .spam {
  display: none;
}
#navbar .lhs {
  display: none;
}
#navbar .nav-list {
  display: none;
}
#navbar .brand {
  position: absolute;
  width: min(100%, 1300px);
  pointer-events: none; /*Can click through to navlist and spam, but can't click to go home*/

  /*font-family: 'Roboto';*/
  font-size: 1.1rem;
  letter-spacing: 0.15rem;
}
#navbar .brand a {
  display: inline-block;
  pointer-events: all;
}
#navbar .brand h1 {
  color: white;
  text-transform: uppercase;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-weight: 500;
}
#navbar .menu-bars {
  margin-left: 30px;
  height: 28px;
  width: 28px;
}
#navbar .menu-bars img {
  height: 28px;
  width: 28px;
}

.dark-toggle {
  height: 44px;
  width: 80px;
  border-radius: 44px;
  margin: 0 10px;
  background: transparent;
  border: 3px solid white;
  position: relative;
  cursor: pointer;
}
.dark-toggle .notch {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-image: url(../../assets/sun-icon.png);
  background-size: contain;
  filter: invert(100%);
  position: absolute;
  top: 5px;
  left: 5px;
  transition: all 0.3s ease;
}
.dark-toggle.dark .notch {
  filter: invert(0%);
  background-image: none;
  box-shadow:
    inset 12px 0px midnightblue,
    inset 12px 0px 1px 2px midnightblue;
  transform: translate(30px, 0) rotate(-25deg);
}

.sidebar .nav-list {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 36px;
}
.sidebar .nav-list ul {
  list-style: none;
  position: initial;
  display: block;
  height: auto;
  width: fit-content;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 1;

  padding-left: 34px;
}
.sidebar .nav-list ul li {
  position: relative;
}
.sidebar .nav-list ul li a {
  font-size: 1.3rem;
  font-weight: 500;
  letter-spacing: 0.2rem;
  text-decoration: none;
  color: white;
  text-transform: uppercase;
  /* Note: Padding doubled due to using both <Link> and <a> (needed <a> for class) */
  padding: 8px 5px;
  display: block;
  text-align: left;
}
.sidebar .nav-list .cu-page {
  text-decoration: underline;
  text-decoration-thickness: 4px;
  text-underline-offset: 12px;
}
.sidebar .sidebar-dark {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 34px;
  background-color: var(--primary);
  border-radius: 25px;
  width: 195px;
}
.sidebar-dark .dark-toggle {
  margin-left: 0;
}
.sidebar-dark .dark-label {
  font-size: 1.1rem;
  font-weight: 400;
  letter-spacing: 0.1rem;
  color: white;
  text-transform: uppercase;
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.sidebar {
  background-color: var(--secondary);
  width: 80vw;
  min-width: min(280px, 100vw);
  height: calc(100vh - 60px);
  display: flex;
  justify-content: flex-start;
  position: fixed;
  top: 60px;
  left: -100%;
  transition: 350ms;
  z-index: 4;
}
.sidebar.active {
  left: 0;
  transition: 350ms;
  z-index: 4;
  box-shadow: 0px 0px 10px black;
}
.side-blur {
  /* Not really a blur but whatever */
  /* TODO: Make this work on tablet */
  background-color: transparent;
  opacity: 0;
  width: 20vw;
  height: 92vh;
  position: fixed;
  top: 60px;
  left: -100%;
}
.side-blur.active {
  left: 80vw;
  z-index: 4;
}

/* Tablet */
@media only screen and (min-width: 768px) {
  #navbar .navbar-container {
    height: 80px;
  }
  #navbar .nav-bar {
    padding-top: 0;
  }
  .sidebar {
    height: calc(100vh - 80px);
    top: 80px;
    width: fit-content;
  }
  .sidebar .nav-list ul {
    padding-left: 58px;
    padding-right: 68px;
  }
  .sidebar .nav-list ul li a {
    font-size: 1.6rem;
  }
  .sidebar .sidebar-dark {
    margin-left: 68px;
    margin-right: 68px;
  }
}

/* Desktop */
@media only screen and (min-width: 1200px) {
  #navbar .nav-bar {
    padding-top: 0;
  }
  #navbar .menu-bars {
    display: none;
  }
  #navbar .brand {
    display: none;
  }
  #navbar .lhs {
    display: flex;
  }
  #navbar .spam {
    display: flex;
  }
  #navbar .nav-list {
    display: flex;
  }

  #navbar .spam {
    color: white;
  }
  #navbar .nav-list ul {
    list-style: none;
    position: initial;
    display: block;
    height: auto;
    width: fit-content;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    padding-left: 30px;
  }
  #navbar .nav-list ul li {
    display: inline-block;
    position: relative;
  }
  #navbar .nav-list ul li a {
    font-size: 1.1rem;
    font-weight: 400;
    letter-spacing: 0.05rem;
    text-decoration: none;
    color: white;
    text-transform: uppercase;
    /* Note: Padding doubled due to using both <Link> and <a> (needed <a> for class) */
    padding: 5px;
    display: block;
  }
  #navbar .nav-list ul li:hover a {
    /* Bold on hover, without shifting other elements */
    /* source: https://pupungbp.com/how-to-give-bold-on-hover-without-shifting-another-element/ */
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
  }
  #navbar .nav-list ul li a:after {
    content: "";
    position: absolute;
    width: calc(100% - 22px);
    transform: scaleX(0);
    margin-top: 12px;
    margin-left: 10px;
    height: 3px;
    bottom: 0;
    left: 0;
    background-color: white;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
    z-index: 1;
  }
  #navbar .nav-list ul li a:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  #navbar .nav-list .cu-page:after {
    transform: scaleX(1);
  }
  .sidebar {
    left: -100%;
  }
  .sidebar.active {
    left: -100%;
  }

  .dark-toggle {
    height: 34px;
    width: 60px;
    border-radius: 34px;
    border: 2px solid white;
    margin-left: 0px;
    margin-right: 30px;
  }

  .dark-toggle .notch {
    height: 25px;
    width: 25px;
  }

  .dark-toggle.dark .notch {
    box-shadow:
      inset 8px 0px midnightblue,
      inset 8px 0px 1px 2px midnightblue;
    transform: translate(20px, 0) rotate(-25deg);
  }
}
