.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.home-container {
  max-width: 1200px;
  margin: 40px 0;
}
