.events {
  display: flex;
  align-items: center;
  justify-content: center;
}
.events-container {
  max-width: 1200px;
  margin: 40px 40px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.events-container h2 {
  text-align: left;
  font-size: 1.75rem;
  font-weight: 400;
  font-style: normal;
  /* text-transform: uppercase; */
  margin-block-start: 10px;
  margin-block-end: 0.2em;
  margin-bottom: 0;
}
.events-upcoming,
.events-previous {
  display: flex;
  flex-direction: column;
}
.event-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Tablet */
@media only screen and (min-width: 768px) {
  .events-container {
    margin-right: 0; /* card margin makes up for in on tablet/desktop */
  }
  .event-list {
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
/* Desktop */
@media only screen and (min-width: 1200px) {
  .events-container h2 {
    font-size: 2.5rem;
  }
}
