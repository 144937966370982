@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
* {
  font-family: "Roboto", sans-serif;
}
.App {
  text-align: center;
  color: var(--text-primary);
  background-color: var(--background);
  transition: all 0.5s;
  overflow: hidden;
}
