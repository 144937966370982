.article {
  display: flex;
  align-items: center;
  justify-content: center;
}
.article-wrapper {
  max-width: min(95vw, 1200px);
  margin: 40px 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}
.article-container {
  max-width: 800px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  transition: color 0.3s ease;
}
.article-container p,
.article-container ul,
.article-container li,
.article-container h1,
.article-container h2,
.article-container h3,
.article-container h4,
.article-container h5,
.article-container h6,
.article-container span {
  text-align: left;
  width: 100%;
  max-width: fit-content;
  color: var(--text-primary);
  /* overflow: hidden; */
  text-overflow: wrap;
  scroll-margin-top: 80px;
}
.article-container ul {
  all: unset;
  display: block;
}
.article-container li {
  margin: 5px 0;
}
.article-container h2,
.article-container h3 {
  font-weight: 500;
}
.article-content {
  width: min(95vw, 758px);
}
.article-content a {
  color: var(--url);
}
.article-img {
  max-width: min(95vw, 758px);
  height: auto;
}
.article-title,
.article-sidebar-title {
  width: 100%;
  margin-bottom: 20px;
}
.article-title h2 {
  text-align: left;
  font-size: 1.75rem;
  font-weight: 400;
  font-style: normal;
  margin-block-start: 10px;
  margin-block-end: 0.2em;
  margin-bottom: 0;
}
.article-title p {
  margin-top: 5px;
  margin-bottom: 10px;
  color: var(--text-light);
}
.article-outline {
  all: unset;
  background: var(--primary);
  width: min(95vw, 758px);
  border-radius: 6px;
  padding: 4px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: auto;
  cursor: pointer;
  border-bottom: 4px solid var(--secondary);
}
.article-outline.active {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom: 0;
}
.article-outline-button {
  padding-left: 15px;
  display: flex;
  align-items: center;
  flex-direction: row;
  color: #fff;
  font-weight: 300;
}
.article-outline-expand {
  filter: invert(1);
  width: 16px;
  height: 16px;
  margin-right: 5px;
  transition: all 0.3s;
  transform: rotate(270deg);
}
.article-outline-expand.active {
  transform: rotate(360deg);
}
.article-outline-content {
  width: min(95vw, 758px);
  background-color: var(--secondary);
  padding: 5px 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-bottom: 4px solid var(--primary);
}
.article-outline-content ul {
  all: unset;
  display: block;
  margin: 0;
  padding: 0;
  color: white;
  list-style: none;
  padding-left: 36px;
}
.article-outline-content li {
  margin: 5px 0;
  font-size: 1rem;
  color: white;
}
.article-outline-content li a {
  color: white;
  font-weight: 300;
}
.article-sidebar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  /* width: 100%; */
  margin-top: 20px;
}
.article-sidebar-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  text-align: left;
  width: 100%;
}
.article-sidebar-title h3 {
  text-align: left;
  font-size: 1.5rem;
  font-weight: 400;
  font-style: normal;
  margin-block-start: 10px;
  margin-block-end: 0.2em;
  margin-bottom: 0;
}

@media only screen and (min-width: 1200px) {
  .article-content {
    padding-right: 42px;
    border-right: 2px solid var(--card-background);
  }
  .article-content p {
    font-size: 1.05rem;
    line-height: 1.5rem;
  }

  .article-wrapper {
    flex-direction: row;
    /* justify-content: space-between; */
  }
  .article-sidebar {
    margin-left: 40px;
    margin-top: 80px;
  }
  .article-title h2 {
    font-size: 2rem;
  }
}
