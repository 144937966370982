.row {
  flex-direction: column-reverse;
  text-align: left;
  margin: 20px;
  display: flex;
  align-items: center;
}
.row .row-text {
  flex-direction: row;
  text-align: left;
  margin: 0px 15px;
}
.row .row-title h2 {
  font-size: 1.75rem;
  font-weight: 400;
  font-style: normal;
  /* text-transform: uppercase; */
  margin-block-start: 10px;
  margin-block-end: 0.2em;
}
.row .row-paragraph p {
  font-size: 1rem;
  text-align: left;
  margin-block-start: 0em;
}
.row .row-paragraph a {
  color: var(--url);
}
.row-image {
  margin-top: 10px;
  margin-left: 15px;
  margin-right: 15px;
}
.row-image img {
  max-height: 500px;
  max-width: 90vw;
  transform: scale(1.01);
}
.row-image.border img {
  border-radius: 20px;
  border: 4px solid var(--text-primary);
  background-color: var(--text-primary);
}

/* Tablet */
@media only screen and (min-width: 768px) {
  .row {
    flex-direction: row;
    align-items: flex-start;
  }
  .row .row-text {
    margin: 0 20px;
  }
  .row .row-paragraph p {
    font-size: 1rem;
    text-align: justify;
  }
  .row-image {
    margin: auto 0;
  }
  .row-image img {
    width: 300px;
  }
}
/* DeskTop */
@media only screen and (min-width: 1200px) {
  .row .row-title h2 {
    font-size: 2.5rem;
  }
  .row .row-paragraph p {
    font-size: 1.2rem;
  }
  .row-image img {
    width: 390px;
  }
  .row-image.border img {
    width: calc(390px - 12px); /* accounting for border */
  }
}
