.mnm {
  display: flex;
  justify-content: center;
}
.mnm-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 10px;
}
.mnm-container img {
  width: 40px;
  transition: 0.3s ease all;
  position: relative;
  z-index: 1;
}
.mnm .mnm-container .mnm-item {
  border-radius: 100%;
}
.mnm .mnm-container .mnm-item.list {
  border-radius: 0%;
}
.mnm-item.email {
  background: var(--secondary);
}
.mnm-item.list {
  background-image: radial-gradient(
      circle at 40px 10px,
      var(--secondary) 18%,
      transparent 19%
    ),
    radial-gradient(circle at center, var(--secondary) 56%, transparent 57%);
}
.mnm-item.donate {
  background: var(--secondary);
}
.mnm-wrapper {
  margin: 0 5px;
  display: flex;
  flex-direction: row;
  border-radius: 25px;
  background-color: transparent;
  height: 40px;
  width: 100%;
  transition: all 0.1s ease-out;
  z-index: 1;
}
.mnm-wrapper:hover {
  width: 100%;
  transition: all 0.15s ease-in;
  box-shadow: 0px 0px 5px white;
  transform: scale(1.05);
}
.mnm-wrapper.list {
  margin-top: 0px;
  height: 40px;
  display: flex;
  align-content: center;
  align-items: center;
}
.mnm-wrapper.email {
  width: 100%;
  background-color: var(--secondary);
}
.mnm-wrapper.list {
  width: 100%;
  background-color: var(--secondary);
}
.mnm-wrapper.donate {
  width: 100%;
  background-color: var(--secondary);
}
.mnm-wrapper .mnm-label {
  display: flex;
  align-items: center;

  width: 0;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  transition: width 0.1s ease-out;

  z-index: 0;
  height: 100%;
  justify-content: flex-end;
  overflow: hidden;
}
.mnm-wrapper .mnm-label {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  transition: width 0.15s ease-in;
}
.mnm-wrapper .mnm-label a {
  font-size: 0.9rem;
  font-weight: 400;
  letter-spacing: 0.025rem;
  color: white;
  text-transform: uppercase;
  padding: 0 8px;
  padding-right: 15px;
  white-space: nowrap;

  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.mnm-wrapper.list a {
  margin-left: -3px;
}
.mnm-wrapper.donate {
  margin-left: 7px;
}

/* Tablet & Mobile */
@media only screen and (max-width: 1199px) {
  .mnm-container {
    flex-direction: column;
    align-items: center;
    margin-left: 34px;
  }
  .mnm .mnm-container .mnm-item.email {
    background: var(--primary);
  }
  .mnm .mnm-container .mnm-item.list {
    background-image: radial-gradient(
        circle at 40px 10px,
        var(--primary) 18%,
        transparent 19%
      ),
      radial-gradient(circle at center, var(--primary) 56%, transparent 57%);
  }
  .mnm .mnm-container .mnm-item.donate {
    background: var(--primary);
  }

  .mnm-wrapper {
    min-width: 195px;
    margin-top: 20px;
    margin-left: 0;
    margin-right: 0;
    height: 50px;
  }

  .mnm-wrapper.list .mnm-label a {
    margin-left: 0;
  }
  .mnm-wrapper.list {
    margin-top: 20px;
    height: 50px;
  }

  .mnm-wrapper.email {
    background-color: var(--primary);
  }
  .mnm-wrapper.list {
    background-color: var(--primary);
  }
  .mnm-wrapper.donate {
    background-color: var(--primary);
    margin-bottom: 20px;
    margin-left: 0;
    margin-right: 0;
  }

  .mnm-wrapper .mnm-label {
    display: flex;
    align-items: center;
    width: auto;
    height: 100%;
  }

  .mnm-wrapper .mnm-label a {
    font-size: 1.1rem;
    letter-spacing: 0.1rem;
    padding: 0px 15px;
  }

  .mnm-container img {
    height: 50px;
    width: 50px;
  }
}
/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .mnm {
    justify-content: flex-start;
  }
  .mnm-container {
    margin-left: 68px;
  }
}
