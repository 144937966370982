.video-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-color: var(--card-background);
  margin: 20px;
  width: 330px;
  transition: all 0.3s ease;
}
.video-card-container {
  display: flex;
  flex-direction: column;
  border-radius: 14px;
  margin: 6px;
}
.video-card-image {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: min(90vw, 360px);
}
.video-card-image img {
  width: 100%;
  object-fit: cover;
  border-radius: 14px;
}
.video-card-title {
  display: flex;
  align-items: center;
  justify-content: center;
}
.video-card-title h3 {
  text-align: left;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  width: 100%;
  color: var(--text-primary);
  margin: 5px 0;
  margin-top: 10px;
  padding: 0 10px;

  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.video-card-date {
  display: flex;
  align-items: center;
  justify-content: center;
}
.video-card-date p {
  text-align: left;
  width: 100%;
  font-size: 1rem;
  font-weight: 300;
  font-style: normal;
  color: var(--text-light);
  margin: 0;
  margin-bottom: 10px;
  padding: 0 10px;
}
.video-card-view {
  background-color: var(--primary);
  width: 100%;
  height: 40px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: auto;

  cursor: pointer;
}
.video-card-view-button {
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  color: white;
}
.video-card-view-arrow {
  filter: invert(1);
  width: 20px;
  height: 20px;
  margin-left: 10px;
  transition: all 0.6s;
  transform: rotate(270deg);
}

@media only screen and (min-width: 768px) {
  .video-card {
    margin: 20px 40px 20px 0;
  }
}
