.donate-button-container {
  display: flex;
  width: 125px;
  height: 50px;
  align-items: center;
  justify-content: center;
  align-content: center;

  background-color: var(--primary);
  border-radius: 50px;
  margin: 10px 20px;

  transition: all 0.3s ease;
}
.donate-button-container a {
  color: white;
  font-size: 1.35rem;
  font-weight: 500;
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.donate-button-container:hover {
  transform: scale(1.025);
  box-shadow: 0 0 5px var(--text-primary);
}
